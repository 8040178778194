@import "../../../data/styles.css";

.homepage-article {
	width: 90%;
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
}

.homepage-article:hover {
	background: #fafafa;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.homepage-article-content {
	padding: 30px;
}

.homepage-article-date {
	opacity: 1;
	color: #000000;
	font-size: 14px;
	font-weight:600;
	margin-bottom: 1em;
}

.homepage-article-title {
	opacity: 1;
	color: var(--primary-color);
	font-size: 18px;
	margin-bottom: 1em;
	font-weight:800;
}

.homepage-article-description {
	opacity: 1;
	color: #000000;
	font-size: 14px;
	font-weight:600;
	line-height: 1.5;
}

.homepage-article-link {
	opacity: 1;
	padding-top: 20px;
	font-size: 15px;
	font-weight: 700;
}

.homepage-article-link a {
	color: var(--link-color);
	text-decoration: none;
}

@media (max-width: 600px) {
	.homepage-article {
		width: 100%;
	}
}
