.about-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.about-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.about-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.about-main {
	display: flex;
}

.about-right-side {
	flex-basis: 900px;
}

.about-title {
	width: 100% !important;
}

.about-subtitle {
	width: 80% !important;
}

.about-left-side {
	display: flex;
	flex-direction: column;
	flex-basis: 300px;
}

.about-image-container {
	width: 370px;
}

.about-image-wrapper {
	display: inline-block;
	
	width: 100%;
	border-radius: 10%;
	transform: rotate(3deg);
}

.about-image-wrapper img {
	display: block;
	width: 100%;
}

.about-socials {
	padding-top: 50px;
	padding-bottom: 50px;
}

.about-socials-mobile {
	display: none;
}

.about-works {
	flex-basis: 600px;
	padding-top: 30px;
}

.about-works-mobile{
	display:none;
}

.work-container{
	display: flex;
	flex-direction: row;
	padding: 1rem 5rem;
	margin-top: 5rem;
	margin-bottom: 3rem;
}

.work-employers-list{
	
	background-color: #B5B8BA;
	display: flex;
	flex-direction: column;
	height: 408px
}

.work-employer-name{
	width: 105px;
	background-color: #c4cad0;
	font-weight: 600;
	padding: 0.5rem 1rem;
	cursor: pointer;
}

.work-employer-name-selected{
	border-left: 5px solid var(--link-color);
	color: #fcf7ff;
	width: 100px;
	background-color: #483D3D;
	padding: 0.5rem 1rem;
	cursor: pointer;
}

.work-experience-container{
	/* background-color: black; */
	background-color: #B5B8BA; /* #fcf7ff; */
	height:400px;
	padding-top: 0.5rem;
	width: 800px;
	padding-left: 1rem;
	display: inline-block;
	overflow: scroll;
	overflow-x:hidden;
}

.work-experience-title{
	font-weight:bold;
	font-size: 20px;
}

.work-experience-date{
	font-size: 16px;
	font-weight:00;
}

.skill-container{
	background-color: #fcf7ff;
	color: black;
	font-weight: 800;
	padding: 0.25rem 0.5rem;
	border-radius: 2rem;
	cursor: default;
	width: auto;
}

.skill-container:hover{
	background-color: #483D3D;
	color: var(--link-color); ;
	font-weight: 800;
	padding: 0.25rem 0.5rem;
	border-radius: 2rem;
}

.work-experience-description{
	font-weight: 500;
}
.icon{
	height: 0;
	width: 0;
	scale: 2;
	transform: rotate(135deg);
	border-top: 1px solid white;
	border-left: 1px solid white;
	border-bottom: 3px solid transparent;
	border-right: 3px solid transparent;
}

@media (max-width: 1024px) {
	.about-logo {
		z-index: 100;
	}

	.about-main {
		flex-direction: column;
	}

	.about-subtitle {
		padding-top: 20px !important;
	}

	.about-left-side {
		flex-basis: 100%;
		order: 1;
	}

	.about-right-side {
		flex-basis: 100%;
		order: 2;
	}

	.about-image-container {
		padding-bottom: 40px;
	}

	.about-socials {
		display: none;
	}

	.about-socials-mobile {
		padding-top: 30px;
		display: flex;
	}
	.about-works{
		display:none;
	}
	.about-works-mobile{
		padding-top: 30px;
		display:flex;
	}
}

@media (max-width: 600px) {
	.about-container {
		padding-top: 60px;
	}

	.about-image-container {
		width: 80%;
	}
}
